.App {
  text-align: center;
}

.hidden {
  opacity: 0;
}

.disp-none {
  display: none;
  visibility: hidden;
}

.color-black-brown {
  color: #201E20;
}

.color-tan {
  color: #E0A96D;
}

.color-beige {
  color: #DDC3A5;
}

.color-charcoal {
  color: #3C4048
}

.color-grey {
  color: #B2B2B2
}

.color-ivory {
  color: #EAEAEA;
}

.color-teal {
  color: #00ABB3;
}

.color-bg-charcoal {
  background: #3C4048;
}

.color-bg-black-brown {
  background: #201E20;
}

.color-bg-ivory {
  background: #EAEAEA;
}

.color-bg-teal {
  background: #00ABB3;
}

.color-outline-ivory {
  border-color: #EAEAEA;
}

.color-outline-charcoal {
  border-color: #3C4048;
}

h1 {
  font-family: 'Work Sans';
}

p {
  font-family: 'Merriweather';
  font-size: min(25px, 5vw);
}

.flex-row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}

.flex-col {
  display: flex;
  flex-direction: column;
}

.page-not-found {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #DDC3A5;
}

.page-not-found-content {
  display: flex;
  height: 100vh;
  width: 50vw;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.page-not-found-content h1 {
  font-family: 'JetBrains Mono';
  font-size: min(400px, 25vw);
  margin: 0
}

.page-not-found-content span {
  margin: 0;
  font-size: min(25px, 5vw);
  font-family: 'Merriweather';
}

.page-not-found-link {
  border: solid;
  text-decoration: none;
  font-family: 'JetBrains Mono';
  width: fit-content;
  font-size: larger;
  padding: 20px;
  margin: 20px;
}

.page-construction {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #EAEAEA;
}

.construction-icon {
  margin: 3rem;
}

.page-contact {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #EAEAEA;
}

.contact-body {
  margin-top: 30vh;
  margin-bottom: 5vh;
  width: min(70vw, 1500px);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.contact-body p {
  text-align: start;
}

.contact-body h1 {
  font-size: min(10vw, 100px);
  margin-bottom: 0;
}

.contact-row {
  display: flex;
  gap: 1rem;
}

.contact-row a {
  text-decoration: none;
  font-family: 'JetBrains Mono';
}

.page-art {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #EAEAEA;
}

.art-body {
  margin-top: 20vh;
  margin-bottom: 20vh;
  width: min(70vw, 1500px);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.art-body h1 {
  text-align: start;
}

.art-images-container {
  display: flex;
  flex-wrap: wrap;
  width: 105%;
  justify-content: flex-start;
}

.art-image-container-outer {
  aspect-ratio: 1 / 1;
  width: min(400px, 100%);
  margin: 0.5em;
}

.art-image-container {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  cursor: pointer;
}

.art-expand-icon {
  padding: 0.5rem;
}

.art-modal {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  backdrop-filter: blur(50px);
}

.art-modal-image {
  max-width: 80%;
  max-height: 80%;
  border-radius: 1rem;
}

.art-modal-exit-icon {
  cursor: pointer;
  position: fixed;
  right: 2rem;
  top: 2rem;
}

.art-modal-loading-icon {
  margin: 5rem;
}

.art-commission-modal {
  position: fixed;
  bottom: 5rem;
  width: min(80vw, 1000px);
  padding: 1rem;
  display: flex;
  flex-direction: column;
  border-radius: 1rem;
  text-align: start;
  filter: drop-shadow(0 0.5rem 0.5rem grey)
}

.art-commission-modal-close-icon {
  cursor: pointer;
}

.art-commission-modal span {
  font-family: 'Merriweather';
}

.art-commission-modal a {
  text-decoration: none;
  margin-top: 0.5rem;
  font-family: 'JetBrains Mono';
}

.art-commission-modal-header {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
}

.art-commission-modal-header h1 {
  margin-top: 0;
}

.page-home {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #201E20;
}

.home-header-container {
  display: flex;
  flex-direction: row;
}

.home-header-img {
  max-width: 50%;
  aspect-ratio: 1/1;
  height: 100%;
  margin-right: 1rem;
}

.home-header-img img {
  height: 100%;
  border-radius: 1rem;
}

.home-header-text {
  text-align: start;
  height: min-content;
}

.home-intro-section {
  display: flex;
  margin-top: 30vh;
  margin-bottom: 5vh;
  width: min(70vw, 1500px);
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.home-intro-section h1 {
  font-size: min(10vw, 100px);
  text-align: start;
  margin-top: 0;
  margin-bottom: 0;
}

.home-intro-section span {
  font-family: 'JetBrains Mono';
  font-size: min(25px, 5vw);
}

.home-intro-section p {
  text-align: start;
  margin-top: 10px;
  margin-bottom: 0;
}

.home-link-container {
  margin-top: 4em;
  text-align: left;
  display: flex;
  flex-wrap: wrap;
}

.home-cta-link {
  font-family: 'JetBrains Mono';
  text-decoration: none;
  margin-right: 4em;
  margin-top: 1em;
  border: solid;
  padding: 20px;
  font-size: larger;
  size: fit-content;
}

.dev-body {
  display: flex;
  margin-top: 15vh;
  margin-bottom: 5vh;
  width: min(70vw, 1500px);
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  text-align: start;
}

.dev-body h1 {
  /* font-size: min(10vw, 100px); */
  text-align: start;
  margin-top: 0;
  margin-bottom: 1rem;
}

.dev-xp-container {
  border: solid;
  border-color: #EAEAEA;
  border-radius: 1rem;
  margin: 1rem;
  padding: 1rem;
  flex-basis: 0;
  flex: 1 1 0px;
  margin-bottom: 3rem;
}

.dev-xp-container h1 {
  font-size: 25px;
  margin-bottom: 0.5rem;
}

.dev-xp-container span {
  font-family: 'JetBrains Mono';
}

.dev-xp-container p {
  margin-top: 1rem;
  font-size: large;
}

.project-card {
  border-radius: 1rem;
  margin: 1rem;
  display: flex;
  height: 25rem;
}

.project-card-content {
  backdrop-filter: blur(50px);
  padding: 1rem;
  border-radius: 1rem;
  flex-grow: 1;
}

.project-card-util-bar {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 1rem; 
}

.project-card-util-bar a {
  text-decoration: none;
  font-family: 'JetBrains Mono';
}

.project-card h1 {
  font-size: 25px;
}

.project-card span {
  font-family: 'JetBrains Mono';
}

.project-card p {
  font-size: large;
}

.menu-bar {
  position: fixed;
  top: 0;
  width: 100vw;
  display: flex;
  justify-content: center;
}

.menu-bar h1 {
  font-size: 50px
}

.menu-content {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 80vw;
}

.menu-nav {
  backdrop-filter: blur(50px);
  border-bottom: 3px solid white;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  margin-top: 0;
  height: 100vh;
  overflow: hidden;
  z-index: 9;
  transform: translateY(-100vh);
  transition: all ease-in-out 1s;
}

.menu-nav.show-menu-nav {
  transform: translateY(0px);
}

.menu-nav-list {
  display: flex;
  flex-direction: column;
  text-align: left;
}

.nav-link {
  text-decoration: none;
  font-size: xx-large;
  font-family: 'JetBrains Mono';
}

.menu-icon {
  z-index: 10;
  cursor: pointer;
  transition: all ease-in-out 1s;
}

.menu-icon.close-nav {
  transform: rotate(180);
}

.socials-menu {
  position: fixed;
  bottom: 3em;
  right: 3em;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.socials-icon {
  cursor: pointer 
}
